import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { tokens } from '../../../theme';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LineChart from '../../../LineChart';
import BarChart from '../../../BarChart';
import StatBox from '../../../StatBox';
import ProgressCircle from '../../../ProgressCircle';
import React, { useEffect, useState } from 'react';
import CodeBox from '../../../CodeBox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SavingsIcon from '@mui/icons-material/Savings';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';
import WelcomeDialog from './WelcomeDialog'; // Import the WelcomeDialog component



function Dashboard(props) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  const [isLoadingPacks, setIsLoadingPacks] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activePacks, setActivePacks] = React.useState([]);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    total_gains: 0.00,
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00 },
  });
  const [token, setToken] = useState('');
  const { t, i18n } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility


  const getData = async () => {
    await axios.get(
        `${config.baseURL}/api/v1/info/user/dashboard`,
        {
          headers: {
            Authorization: 'Bearer ' + props.token,
          },
        },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData({
          ...response.data,
          balance: response.data.balance || { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00 }
        });
        setIsLoading(false);
      }
    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const getActivePacks = async () => {
    try {
      await axios.get(
        `${config.baseURL}/api/v1/info/user/active_packs`,
        {
          headers: {
            Authorization: 'Bearer ' + props.token,
          },
        },
      ).then(response => {
        setIsLoadingPacks(false);
        setActivePacks(response.data);
      });

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData().then(r => console.log(r));
    getActivePacks().then(r => console.log(r));
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box m="10px">
      {/*<WelcomeDialog*/}
      {/*    open={dialogOpen}*/}
      {/*    onClose={handleDialogClose}*/}
      {/*    content={t("info_withdraw_content")}*/}
      {/*/>*/}
      {/* HEADER */}
      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            {t("dashboard_title") + userData.firstname + ' ' + userData.lastname}
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            {t("dashboard_subtitle")}
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={parseFloat(userData.cycle_gains).toLocaleString()}
              subtitle={t("dashboard_cycle_gains")}
              progress="0.75"
              icon={
                <MonetizationOnIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={parseFloat(userData.referral_gains).toLocaleString()}
              subtitle={t("dashboard_referral_gains")}
              progress="0.50"
              icon={
                <PersonAddIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gridColumn={{ xs: 1, sm: 2, md: 3 }}
          >
            <StatBox
              title={parseFloat(userData.total_gains).toLocaleString()}
              subtitle={t("dashboard_total_revenue")}
              progress="1"
              icon={
                <AccountBalanceWalletIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CodeBox
              title={userData.ref_id}
              subtitle={t("dashboard_referral_code")}
              icon={
                <PersonAddIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          container
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                height="135px"
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    {t("dashboard_packs_revenue")}
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.greenAccent[500]}
                  >
                    ${parseFloat(userData.cycle_gains).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart token={props.token} isDashboard={true} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]} p="30px">
              <Typography variant="h5" fontWeight="600">
                {t("dashboard_total_revenue")}
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <ProgressCircle size="125" progress={'1'} />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: '15px' }}
                >
                  ${parseFloat(userData.cycle_gains + userData.referral_gains).toLocaleString()}
                </Typography>
                <Typography>
                  {t("dashboard_total_revenue_generated")}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]}>
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: '30px 30px 0 30px' }}
              >
                {t("dashboard_bonus_bar_chart")}
              </Typography>
              <Box height="250px" mt="-13px">
                <BarChart token={props.token} isDashboard={true} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            backgroundColor="#285c9a"
            minHeight={'664.333px'}
            overflow="auto"
            m="25px 0 0 0"

          >
            {isLoadingPacks ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight={'664.333px'}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {Array.isArray(activePacks) && activePacks.length > 0 ? (
                    <Carousel showThumbs={false} showStatus={false} swipeable={true} interval={2}>
                      {activePacks.map((product, index) => (
                          <Box p="30px" minHeight={'664.333px'} display="flex" flexDirection="column"
                               justifyContent="space-between" key={index}>
                            <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                              <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                                {t("dashboard_active_packs")}
                              </Typography>
                            </Box>
                            <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                              <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                                {t(product.name)}
                              </Typography>
                            </Box>
                            <Box bgcolor="#e0e0e0" borderRadius="8px">
                              {Array.isArray(product.description) && product.description.map((desc, i) => (
                                  <Typography key={i} variant="h4" color="primary" textAlign={'center'} m={'20px'}>
                                    {t(desc)}
                                  </Typography>
                              ))}
                            </Box>
                            <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                              <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                                {t("dashboard_active_pack_investment")}
                              </Typography>
                              <Typography variant="h4" color="primary" sx={{ textAlign: 'center' }}>
                                {parseFloat(product.price).toLocaleString()}
                              </Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="center">
                              <ProgressCircle size="150" progress={String(product.percentage * 100)} max="300" />
                              <Typography variant="h5" color="#000000" fontWeight="bold">
                                {parseFloat(product.percentage * 100).toLocaleString()}%
                              </Typography>
                            </Box>
                          </Box>
                      ))}
                    </Carousel>
                ) : (
                    <Box p="30px" minHeight={'664.333px'} display="flex" flexDirection="column"
                         justifyContent="space-between">
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                          {t("dashboard_active_packs")}
                        </Typography>
                      </Box>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }}>
                          {t("dashboard_no_active_packs")}
                        </Typography>
                      </Box>
                      <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                        <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                          {t("dashboard_no_active_packs_purchase")}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Button variant="contained" color="primary" sx={{ mt: '10px', mx: 'auto' }}
                                onClick={() => navigate('/home/packs')}>
                          {t("dashboard_purchase_button")}
                        </Button>
                      </Box>
                    </Box>
                )}
              </>
            )}
          </Box>
        </Grid>

        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
                title={parseFloat(userData.balance.for_savings).toLocaleString()}
                subtitle={t("dashboard_savings_funds")}
                progress="0.33"
                increase="33%"
                icon={
                  <SavingsIcon
                      sx={{ color: '#FFFFFF', fontSize: '26px' }}
                  />
                }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={parseFloat(userData.balance.for_products).toLocaleString()}
              subtitle={t("dashboard_store_funds")}
              progress="0.05"
              increase="5%"
              icon={
                <LocalGroceryStoreIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={parseFloat(userData.balance.for_withdraw).toLocaleString()}
              subtitle={t("dashboard_withdraw_funds")}
              progress="0.62"
              increase="62%"
              icon={
                <MonetizationOnIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#285c9a"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gridColumn={{ xs: 1, sm: 2, md: 3 }}
          >
            <StatBox
              title={parseFloat(userData.balance.total ? userData.balance.total : '0').toLocaleString()}
              subtitle={t("dashboard_total_wallet_balance")}
              progress="1"
              // increase="+5%"
              icon={
                <AccountBalanceWalletIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
    )}
    </Box>
  );
}

export default Dashboard;
