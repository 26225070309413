const config = {
  development: {
    baseURL: 'http://localhost:5000',
    siteURL: 'http://localhost:3000',
    salt: '$2a$10$Q6v6Y6Z6Zxrtcfygvbhuijnklrtgyhjklmxerctfvgybhunjkml'
  },
  test: {
    baseURL: 'http://localhost:5000',
    siteURL: 'http://localhost:3000',
    salt: '$2a$10$Q6v6Y6Z6Zxrtcfygvbhuijnklrtgyhjklmxerctfvgybhunjkml'
  },
  production: {
    // baseURL: 'https://techbluedefi.io/RXFAXHCXVLTBQHNIHNABXLVCAFEWFRHK',
    baseURL: 'https://techbluedefi.io/api',
    siteURL: 'https://techbluedefi.io',
    salt: '$2a$10$Q6v6Y6Z6Zxrtcfygvbhuijnklrtgyhjklmxerctfvgybhunjkml'
  },
};

export default config[process.env.NODE_ENV || 'development'];